import clsx from "clsx"
import * as React from "react"

interface Props {
	classes?: string
	color?: string
	accentColor?: string
}

const Logo: React.FC<Props> = ({
	classes = "",
	color = "text-white",
	accentColor = "text-gold-200",
}) => {
	return (
		<svg
			className={clsx(classes)}
			width="90"
			height="22"
			viewBox="0 0 90 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 7.848V0.12H12.492L15.468 6.204L18.432 0.12H18.924V7.848H18.588V0.492L15.66 6.492H15.276L12.336 0.492V7.848H12Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M29.9797 7.968C29.1957 7.968 28.5557 7.808 28.0597 7.488C27.5637 7.168 27.1957 6.716 26.9557 6.132C26.7237 5.54 26.6077 4.848 26.6077 4.056C26.6077 2.672 26.8757 1.652 27.4117 0.996C27.9477 0.332 28.8037 0 29.9797 0C31.1557 0 32.0117 0.332 32.5477 0.996C33.0837 1.652 33.3517 2.672 33.3517 4.056C33.3517 4.848 33.2317 5.54 32.9917 6.132C32.7597 6.716 32.3957 7.168 31.8997 7.488C31.4037 7.808 30.7637 7.968 29.9797 7.968ZM29.9797 7.668C31.0037 7.668 31.7677 7.36 32.2717 6.744C32.7757 6.128 33.0277 5.232 33.0277 4.056C33.0277 3.136 32.9157 2.404 32.6917 1.86C32.4677 1.308 32.1277 0.912 31.6717 0.672C31.2237 0.424 30.6597 0.3 29.9797 0.3C29.2997 0.3 28.7317 0.424 28.2757 0.672C27.8277 0.912 27.4917 1.308 27.2677 1.86C27.0437 2.404 26.9317 3.136 26.9317 4.056C26.9317 5.232 27.1837 6.128 27.6877 6.744C28.1917 7.36 28.9557 7.668 29.9797 7.668Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M41.0273 7.848V0.12H44.2553C45.0153 0.12 45.5553 0.292 45.8753 0.636001C46.2033 0.98 46.3673 1.472 46.3673 2.112C46.3673 2.552 46.2713 2.916 46.0793 3.204C45.8953 3.484 45.6393 3.692 45.3113 3.828C45.7353 3.908 46.0713 4.096 46.3193 4.392C46.5673 4.688 46.6913 5.116 46.6913 5.676C46.6913 6.436 46.5113 6.988 46.1513 7.332C45.7913 7.676 45.2433 7.848 44.5073 7.848H41.0273ZM41.3633 3.684H44.4353C45.0273 3.684 45.4433 3.536 45.6833 3.24C45.9233 2.936 46.0433 2.56 46.0433 2.112C46.0433 1.752 45.9913 1.448 45.8873 1.2C45.7833 0.944 45.5993 0.752 45.3353 0.624C45.0713 0.488 44.6913 0.42 44.1953 0.42H41.3633V3.684ZM41.3633 7.548H44.5553C45.1793 7.548 45.6353 7.404 45.9233 7.116C46.2193 6.828 46.3673 6.348 46.3673 5.676C46.3673 5.132 46.2233 4.716 45.9353 4.428C45.6553 4.132 45.1273 3.984 44.3513 3.984H41.3633V7.548Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M54.375 7.848V0.12H54.711V7.848H54.375Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M62.6367 7.848V0.12H62.9727V7.548H67.4127V7.848H62.6367Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M74.8477 7.848V0.12H75.1837V7.848H74.8477Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M0 21.848V14.12H0.492L3.468 20.204L6.432 14.12H6.924V21.848H6.588V14.492L3.66 20.492H3.276L0.336 14.492V21.848H0Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M14.8477 21.848V14.12H19.7437V14.42H15.1837V17.828H19.3237V18.128H15.1837V21.548H19.7437V21.848H14.8477Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M27.3047 21.848V14.12H27.6767L32.8367 21.392V14.12H33.1727V21.848H32.8007L27.6407 14.576V21.848H27.3047Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M40.8577 21.848L43.7737 14.12H44.3137L47.2177 21.848H46.8817L45.9817 19.448L44.0377 14.312L42.1057 19.448L41.2057 21.848H40.8577Z"
				className={clsx(accentColor, "fill-current")}
			/>
			<path
				d="M54.9023 21.848V14.12H55.2383V21.548H59.6783V21.848H54.9023Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M67.1133 21.848V14.12H69.9093C70.6373 14.12 71.2173 14.256 71.6493 14.528C72.0893 14.792 72.4053 15.212 72.5973 15.788C72.7973 16.364 72.8973 17.116 72.8973 18.044C72.8973 18.916 72.8013 19.636 72.6093 20.204C72.4253 20.764 72.1133 21.18 71.6733 21.452C71.2413 21.716 70.6533 21.848 69.9093 21.848H67.1133ZM67.4493 21.548H69.7893C70.2613 21.548 70.6693 21.5 71.0133 21.404C71.3653 21.3 71.6573 21.12 71.8893 20.864C72.1213 20.608 72.2933 20.252 72.4053 19.796C72.5173 19.34 72.5733 18.756 72.5733 18.044C72.5733 17.1 72.4813 16.368 72.2973 15.848C72.1133 15.32 71.8173 14.952 71.4093 14.744C71.0093 14.528 70.4693 14.42 69.7893 14.42H67.4493V21.548Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M83.7101 21.968C82.9261 21.968 82.2861 21.808 81.7901 21.488C81.2941 21.168 80.9261 20.716 80.6861 20.132C80.4541 19.54 80.3381 18.848 80.3381 18.056C80.3381 16.672 80.6061 15.652 81.1421 14.996C81.6781 14.332 82.5341 14 83.7101 14C84.8861 14 85.7421 14.332 86.2781 14.996C86.8141 15.652 87.0821 16.672 87.0821 18.056C87.0821 18.848 86.9621 19.54 86.7221 20.132C86.4901 20.716 86.1261 21.168 85.6301 21.488C85.1341 21.808 84.4941 21.968 83.7101 21.968ZM83.7101 21.668C84.7341 21.668 85.4981 21.36 86.0021 20.744C86.5061 20.128 86.7581 19.232 86.7581 18.056C86.7581 17.136 86.6461 16.404 86.4221 15.86C86.1981 15.308 85.8581 14.912 85.4021 14.672C84.9541 14.424 84.3901 14.3 83.7101 14.3C83.0301 14.3 82.4621 14.424 82.0061 14.672C81.5581 14.912 81.2221 15.308 80.9981 15.86C80.7741 16.404 80.6621 17.136 80.6621 18.056C80.6621 19.232 80.9141 20.128 81.4181 20.744C81.9221 21.36 82.6861 21.668 83.7101 21.668Z"
				className={clsx(color, "fill-current")}
			/>
		</svg>
	)
}

export default Logo
