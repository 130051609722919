import clsx from "clsx"
import * as React from "react"

interface Props {
	classes?: string
	color?: string
	accentColor?: string
}

const Logo: React.FC<Props> = ({
	classes = "",
	color = "text-white",
	accentColor = "text-gold-200",
}) => {
	return (
		<svg
			className={clsx(classes)}
			width="90"
			height="22"
			viewBox="0 0 90 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 7.848V0.12H13.344L15.792 5.124L18.228 0.12H19.572V7.848H18.276V2.244L16.188 6.516H15.396L13.296 2.244V7.848H12Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M30.502 7.968C29.278 7.968 28.362 7.648 27.754 7.008C27.146 6.36 26.842 5.36 26.842 4.008C26.842 2.6 27.146 1.58 27.754 0.948C28.362 0.316 29.278 0 30.502 0C31.734 0 32.65 0.316 33.25 0.948C33.858 1.58 34.162 2.6 34.162 4.008C34.162 5.36 33.858 6.36 33.25 7.008C32.65 7.648 31.734 7.968 30.502 7.968ZM30.502 6.864C31.31 6.864 31.902 6.644 32.278 6.204C32.662 5.756 32.854 5.024 32.854 4.008C32.854 2.936 32.662 2.188 32.278 1.764C31.902 1.332 31.31 1.116 30.502 1.116C29.702 1.116 29.11 1.332 28.726 1.764C28.35 2.188 28.162 2.936 28.162 4.008C28.162 5.024 28.35 5.756 28.726 6.204C29.11 6.644 29.702 6.864 30.502 6.864Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M41.4375 7.848V0.12H45.1815C45.9495 0.12 46.5175 0.3 46.8855 0.66C47.2535 1.02 47.4375 1.528 47.4375 2.184C47.4375 2.6 47.3535 2.952 47.1855 3.24C47.0175 3.52 46.7935 3.732 46.5135 3.876C46.8895 3.98 47.1855 4.168 47.4015 4.44C47.6255 4.704 47.7375 5.096 47.7375 5.616C47.7375 6.368 47.5335 6.928 47.1255 7.296C46.7255 7.664 46.1335 7.848 45.3495 7.848H41.4375ZM42.7455 3.42H45.0015C45.4415 3.42 45.7535 3.32 45.9375 3.12C46.1295 2.912 46.2255 2.636 46.2255 2.292C46.2255 1.892 46.1295 1.596 45.9375 1.404C45.7455 1.204 45.3855 1.104 44.8575 1.104H42.7455V3.42ZM42.7455 6.888H45.0735C45.5375 6.888 45.8775 6.792 46.0935 6.6C46.3095 6.4 46.4175 6.056 46.4175 5.568C46.4175 5.168 46.3095 4.872 46.0935 4.68C45.8855 4.48 45.5015 4.38 44.9415 4.38H42.7455V6.888Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M55.0078 7.848V0.12H56.3158V7.848H55.0078Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M63.832 7.848V0.12H65.14V6.84H69.1V7.848H63.832Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M76.1367 7.848V0.12H77.4447V7.848H76.1367Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M0 21.848V14.12H1.344L3.792 19.124L6.228 14.12H7.572V21.848H6.276V16.244L4.188 20.516H3.396L1.296 16.244V21.848H0Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M15.082 21.848V14.12H20.47V15.14H16.39V17.48H20.026V18.488H16.39V20.84H20.47V21.848H15.082Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M27.6797 21.848V14.12H28.8317L32.8637 19.808V14.12H34.1597V21.848H33.0077L28.9877 16.172V21.848H27.6797Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M41.4556 21.848L44.4076 14.12H45.8716L48.7996 21.848H47.4556L46.6876 19.856L45.1276 15.308L43.5796 19.856L42.8116 21.848H41.4556Z"
				className={clsx(accentColor, "fill-current")}
			/>
			<path
				d="M56.0859 21.848V14.12H57.3939V20.84H61.3539V21.848H56.0859Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M68.3906 21.848V14.12H71.5226C72.6666 14.12 73.5146 14.424 74.0666 15.032C74.6186 15.632 74.8946 16.632 74.8946 18.032C74.8946 19.352 74.6226 20.32 74.0786 20.936C73.5346 21.544 72.6826 21.848 71.5226 21.848H68.3906ZM69.6986 20.84H71.3186C71.8226 20.84 72.2426 20.768 72.5786 20.624C72.9146 20.472 73.1666 20.192 73.3346 19.784C73.5026 19.376 73.5866 18.792 73.5866 18.032C73.5866 17.272 73.5106 16.684 73.3586 16.268C73.2066 15.852 72.9626 15.56 72.6266 15.392C72.2986 15.224 71.8626 15.14 71.3186 15.14H69.6986V20.84Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M85.6036 21.968C84.3796 21.968 83.4636 21.648 82.8556 21.008C82.2476 20.36 81.9436 19.36 81.9436 18.008C81.9436 16.6 82.2476 15.58 82.8556 14.948C83.4636 14.316 84.3796 14 85.6036 14C86.8356 14 87.7516 14.316 88.3516 14.948C88.9596 15.58 89.2636 16.6 89.2636 18.008C89.2636 19.36 88.9596 20.36 88.3516 21.008C87.7516 21.648 86.8356 21.968 85.6036 21.968ZM85.6036 20.864C86.4116 20.864 87.0036 20.644 87.3796 20.204C87.7636 19.756 87.9556 19.024 87.9556 18.008C87.9556 16.936 87.7636 16.188 87.3796 15.764C87.0036 15.332 86.4116 15.116 85.6036 15.116C84.8036 15.116 84.2116 15.332 83.8276 15.764C83.4516 16.188 83.2636 16.936 83.2636 18.008C83.2636 19.024 83.4516 19.756 83.8276 20.204C84.2116 20.644 84.8036 20.864 85.6036 20.864Z"
				className={clsx(color, "fill-current")}
			/>
		</svg>
	)
}

export default Logo
