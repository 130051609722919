import * as React from "react"
import { LogoCentered } from "../logo"
import { motion } from "framer-motion"
import { useMedia } from "../../hooks/useMedia"

const Loader: React.FC = () => {
	const match = useMedia("(min-width:768px)")

	return (
		<motion.div
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			transition={{
				duration: 0.2,
				when: "beforeChildren",
			}}
			className="w-full h-full z-50 bg-gray-900 flex flex-col items-center justify-center">
			<LogoCentered classes="w-32 md:w-48 h-auto mb-8" />
			<div className="relative w-32 md:w-48 h-1 md:h-2 rounded-full bg-gray-800 overflow-hidden">
				<motion.div
					className="w-10 md:w-15  h-1 md:h-2 rounded-full bg-white absolute top-0 left-0"
					animate={{
						translateX: match ? 152 : 88,
					}}
					transition={{
						duration: 1,
						from: 0,
						repeatType: "reverse",
						repeat: Infinity,
					}}
				/>
			</div>
		</motion.div>
	)
}

export default Loader
