import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { AppLoader } from "./components/loader"

const ComingSoon = React.lazy(() => {
	return Promise.all([
		import("./pages/comingSoon"),
		new Promise(resolve => setTimeout(resolve, 2000)),
	]).then(([moduleExports]) => moduleExports)
})

const Home = React.lazy(() => {
	return Promise.all([
		import("./pages/home"),
		new Promise(resolve => setTimeout(resolve, 2000)),
	]).then(([moduleExports]) => moduleExports)
})

const App: React.FC = () => {
	return (
		<React.Suspense fallback={<AppLoader />}>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="coming-soon" element={<ComingSoon />} />
				<Route path="404" element={<div>404</div>} />
				<Route path="*" element={<Navigate to="/coming-soon" />} />
			</Routes>
		</React.Suspense>
	)
}

export default App
