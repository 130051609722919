import * as React from "react"

export function useMedia(query: string) {
	const [matches, setMatches] = React.useState(false)

	React.useEffect(() => {
		const media = window.matchMedia(query)
		if (media.matches !== matches) {
			setMatches(media.matches)
		}
		const listener = () => {
			setMatches(media.matches)
		}
		media.addEventListener("change", listener)
		return () => media.removeEventListener("change", listener)
	}, [matches, query])

	return matches
}
