import clsx from "clsx"
import * as React from "react"

interface Props {
	classes?: string
	color?: string
	accentColor?: string
}

const Logo: React.FC<Props> = ({
	classes = "",
	color = "text-white",
	accentColor = "text-gold-200",
}) => {
	return (
		<svg
			width="90"
			height="8"
			viewBox="0 0 90 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={clsx(classes)}>
			<path
				d="M0 7.848V0.119999H1.344L3.792 5.124L6.228 0.119999H7.572V7.848H6.276V2.244L4.188 6.516H3.396L1.296 2.244V7.848H0Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M15.082 7.848V0.119999H20.47V1.14H16.39V3.48H20.026V4.488H16.39V6.84H20.47V7.848H15.082Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M27.6797 7.848V0.119999H28.8317L32.8637 5.808V0.119999H34.1597V7.848H33.0077L28.9877 2.172V7.848H27.6797Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M41.4556 7.848L44.4076 0.119999H45.8716L48.7996 7.848H47.4556L46.6876 5.856L45.1276 1.308L43.5796 5.856L42.8116 7.848H41.4556Z"
				className={clsx(accentColor, "fill-current")}
			/>
			<path
				d="M56.0859 7.848V0.119999H57.3939V6.84H61.3539V7.848H56.0859Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M68.3906 7.848V0.119999H71.5226C72.6666 0.119999 73.5146 0.424001 74.0666 1.032C74.6186 1.632 74.8946 2.632 74.8946 4.032C74.8946 5.352 74.6226 6.32 74.0786 6.936C73.5346 7.544 72.6826 7.848 71.5226 7.848H68.3906ZM69.6986 6.84H71.3186C71.8226 6.84 72.2426 6.768 72.5786 6.624C72.9146 6.472 73.1666 6.192 73.3346 5.784C73.5026 5.376 73.5866 4.792 73.5866 4.032C73.5866 3.272 73.5106 2.684 73.3586 2.268C73.2066 1.852 72.9626 1.56 72.6266 1.392C72.2986 1.224 71.8626 1.14 71.3186 1.14H69.6986V6.84Z"
				className={clsx(color, "fill-current")}
			/>
			<path
				d="M85.6036 7.968C84.3796 7.968 83.4636 7.648 82.8556 7.008C82.2476 6.36 81.9436 5.36 81.9436 4.008C81.9436 2.6 82.2476 1.58 82.8556 0.947998C83.4636 0.315998 84.3796 0 85.6036 0C86.8356 0 87.7516 0.315998 88.3516 0.947998C88.9596 1.58 89.2636 2.6 89.2636 4.008C89.2636 5.36 88.9596 6.36 88.3516 7.008C87.7516 7.648 86.8356 7.968 85.6036 7.968ZM85.6036 6.864C86.4116 6.864 87.0036 6.644 87.3796 6.204C87.7636 5.756 87.9556 5.024 87.9556 4.008C87.9556 2.936 87.7636 2.188 87.3796 1.764C87.0036 1.332 86.4116 1.116 85.6036 1.116C84.8036 1.116 84.2116 1.332 83.8276 1.764C83.4516 2.188 83.2636 2.936 83.2636 4.008C83.2636 5.024 83.4516 5.756 83.8276 6.204C84.2116 6.644 84.8036 6.864 85.6036 6.864Z"
				className={clsx(color, "fill-current")}
			/>
		</svg>
	)
}

export default Logo
